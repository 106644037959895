<template>
  <v-app>
    <v-app-bar app color="white" flat>
      <v-container>
        <v-row>
          <v-col cols="auto">
            <h2 v-if="vvt.bezeichnung == '' || vvt.bezeichnung == null">
              Neue Verarbeitungstätigkeit
            </h2>
            <h2 v-else>{{ vvt.bezeichnung }}</h2>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn elevation="0" class="primary">Speichern</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!--<Navigation />-->

    <v-main class="background">
      <v-container class="py-8">
        <v-row>
          <v-col cols="12" md="7">
            <v-sheet elevation="0" outlined rounded>
              <v-item-group class="pa-2" v-model="tab">
                <v-item v-slot="{ active, toggle }" key="0">
                  <v-btn :input-value="active" @click="toggle" class="mr-2" text
                    >Allgemeines</v-btn
                  >
                </v-item>
                <v-item v-slot="{ active, toggle }" key="1">
                  <v-btn :input-value="active" @click="toggle" class="mr-2" text
                    >Löschfristen</v-btn
                  >
                </v-item>
                <v-item v-slot="{ active, toggle }" key="2">
                  <v-btn :input-value="active" @click="toggle" class="mr-2" text
                    >TOM</v-btn
                  >
                </v-item>
              </v-item-group>
              <v-divider></v-divider>
              <v-window v-model="tab">
                <v-window-item key="0">
                  <v-container>
                    <!-- Bezeichnung -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-text-field
                          v-model="vvt.bezeichnung"
                          label="Bezeichnung"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!-- Ansprechpartner -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-autocomplete
                          v-model="vvt.ansprechpartner"
                          :items="ansprechpartner"
                          item-value="id"
                          item-text="name"
                          label="Ansprechpartner"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <!-- Datenschutzbeauftragter -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-autocomplete
                          v-model="vvt.datenschutzbeauftragter"
                          :items="datenschutzbeauftragte"
                          item-value="id"
                          item-text="name"
                          label="Datenschutzbeauftragter"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <!-- Beschreibung der Verarbeitungstätigkeit -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-textarea
                          v-model="vvt.beschreibung"
                          label="Beschreibung der Verarbeitungstätigkeit"
                          outlined
                          dense
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <!-- Zweck der Verarbeitung -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-autocomplete
                          v-model="vvt.zweck"
                          :items="zwecke"
                          item-value="id"
                          item-text="name"
                          :search-input.sync="search_zweck"
                          @change="search_zweck = ''"
                          label="Zweck der Verarbeitung"
                          outlined
                          multiple
                          dense
                        >
                          <template v-slot:no-data>
                            <v-list-item @click="create_zweck()">
                              <v-list-item-title>
                                <strong>{{ search_zweck }}</strong> erstellen
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <!-- Rechtsgrundlage der Verarbeitung -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-autocomplete
                          v-model="vvt.rechtsgrundlage"
                          :items="rechtsgrundlagen"
                          item-value="id"
                          item-text="name"
                          :search-input.sync="search_rechtsgrundlage"
                          @change="search_rechtsgrundlage = ''"
                          label="Rechtsgrundlage der Verarbeitung"
                          outlined
                          multiple
                          dense
                        >
                          <template v-slot:no-data>
                            <v-list-item @click="create_rechtsgrundlage()">
                              <v-list-item-title>
                                <strong>{{ search_rechtsgrundlage }}</strong>
                                erstellen
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <!-- Kategorie(n) personenbezogener Daten -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-autocomplete
                          v-model="vvt.kategorie_personen"
                          :items="kategorien_personen"
                          item-value="id"
                          item-text="name"
                          :search-input.sync="search_kategorie_personen"
                          @change="search_kategorie_personen = ''"
                          label="Kategorie(n) personenbezogener Daten"
                          outlined
                          multiple
                          dense
                          return-object
                        >
                          <template v-slot:no-data>
                            <v-list-item @click="create_kategorie_personen()">
                              <v-list-item-title>
                                <strong>{{ search_kategorie_personen }}</strong>
                                erstellen
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <!-- Besondere Kategorie(n) personenbezogener Daten -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-autocomplete
                          v-model="vvt.besondere_kategorie_personen"
                          :items="besondere_kategorien_personen"
                          item-value="id"
                          item-text="name"
                          :search-input.sync="
                            search_besondere_kategorie_personen
                          "
                          @change="search_besondere_kategorie_personen = ''"
                          label="Besondere Kategorie(n) personenbezogener Daten"
                          outlined
                          multiple
                          dense
                        >
                          <template v-slot:no-data>
                            <v-list-item
                              @click="create_besondere_kategorie_personen()"
                            >
                              <v-list-item-title>
                                <strong>{{
                                  search_besondere_kategorie_personen
                                }}</strong>
                                erstellen
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <!-- Kategorie(n) der Betroffenen -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-autocomplete
                          v-model="vvt.kategorie_betroffene"
                          :items="kategorien_betroffene"
                          item-value="id"
                          item-text="name"
                          :search-input.sync="search_kategorie_betroffene"
                          @change="search_kategorie_betroffene = ''"
                          label="Kategorie(n) der Betroffenen"
                          outlined
                          multiple
                          dense
                        >
                          <template v-slot:no-data>
                            <v-list-item @click="create_kategorie_betroffene()">
                              <v-list-item-title>
                                <strong>{{
                                  search_kategorie_betroffene
                                }}</strong>
                                erstellen
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <!-- Datenübertragung in ein Drittland -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-select
                          v-model="vvt.datenuebertragung"
                          :items="datenuebertragung"
                          item-value="id"
                          item-text="name"
                          label="Datenübertragung in ein Drittland"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>

                    <!-- Kategorie(n) der Empfänger -->
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-autocomplete
                          v-model="vvt.kategorie_empfaenger"
                          :items="kategorien_empfaenger"
                          item-value="id"
                          item-text="name"
                          :search-input.sync="search_kategorie_empfaenger"
                          @change="search_kategorie_empfaenger = ''"
                          label="Kategorie(n) der Empfänger"
                          outlined
                          multiple
                          dense
                        >
                          <template v-slot:no-data>
                            <v-list-item @click="create_kategorie_empfaenger()">
                              <v-list-item-title>
                                <strong>{{
                                  search_kategorie_empfaenger
                                }}</strong>
                                erstellen
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-window-item>
                <v-window-item key="2">
                  <v-container>
                    <v-row>
                      <v-col v-if="vvt.kategorie_personen.length > 0">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Datenkategorie</th>
                                <th class="text-left">Löschfrist</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="kategorie in vvt.kategorie_personen"
                                :key="kategorie.id"
                              >
                                <td>{{ kategorie.name }}</td>
                                <td>
                                  {{ kategorie.frist.name }}
                                </td>
                                <td class="text-right">
                                  <v-btn
                                    icon
                                    small
                                    @click="edit_frist(kategorie.id)"
                                    ><v-icon small>mdi-pencil</v-icon></v-btn
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col v-else>
                        Es wurden noch keine Kategorie(n) personenbezogener
                        Daten ausgewählt.
                      </v-col>
                    </v-row>
                  </v-container>
                </v-window-item>
                <v-window-item key="3">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="vvt.tom"
                          :items="toms"
                          item-value="id"
                          item-text="name"
                          :search-input.sync="search_tom"
                          @change="search_tom = ''"
                          label="Technische und organisatorische Maßnahmen"
                          outlined
                          multiple
                          :dense="vvt.tom.length <= 0"
                          :chips="vvt.tom.length > 0"
                          deletable-chips
                        >
                          <template v-slot:no-data>
                            <v-list-item @click="create_tom()">
                              <v-list-item-title>
                                <strong>{{ search_tom }}</strong>
                                erstellen
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-window-item>
              </v-window>
            </v-sheet>
          </v-col>

          <!--<v-col>
            <v-sheet elevation="0" outlined rounded>
              <v-container>
                <v-row>
                  <v-col>
                    <h3>Risikobewertung</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Nach Vorlage von Bundesdatenschutzbeauftragten oder so?
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-col>-->
        </v-row>

        <v-dialog v-model="frist_dialog.open" width="40vw">
          <v-card v-if="get_kategorie() != undefined">
            <v-card-title>
              {{ get_kategorie().name }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-textarea
                    v-model="get_kategorie().beschreibung"
                    label="Beschreibung"
                    outlined
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="get_kategorie().beispiele"
                    label="Beispiele"
                    outlined
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-autocomplete
                v-model="get_kategorie().frist"
                :items="loeschfrist"
                item-value="id"
                item-text="name"
                :search-input.sync="search_loeschfrist"
                @change="search_loeschfrist = ''"
                label="Standardlöschfrist"
                outlined
                dense
                return-object
              >
                <template v-slot:no-data>
                  <v-list-item @click="create_loeschfrist()">
                    <v-list-item-title>
                      <strong>{{ search_loeschfrist }}</strong>
                      erstellen
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { v4 as uuid } from "uuid";
//import Navigation from "@/components/Navigation";

export default {
  name: "App",

  components: {
    //Navigation,
  },

  data: () => ({
    //tom_dialog: false,
    tab: 0,
    search_zweck: null,
    search_rechtsgrundlage: null,
    search_kategorie_personen: null,
    search_besondere_kategorie_personen: null,
    search_kategorie_betroffene: null,
    search_kategorie_empfaenger: null,
    search_tom: null,
    search_loeschfrist: null,
    frist_dialog: {
      open: false,
      id: null,
    },
    vvt: {
      bezeichnung: null,
      ansprechpartner: null,
      datenschutzbeauftragter: null,
      beschreibung: null,
      zweck: [],
      rechtsgrundlage: [],
      kategorie_personen: [],
      besondere_kategorie_personen: [],
      kategorie_betroffene: [],
      datenuebertragung: null,
      kategorie_empfaenger: [],
      tom: [],
    },
    loeschfristen: {},
    ansprechpartner: [
      {
        id: 1,
        name: "Max Mustermann",
      },
      {
        id: 2,
        name: "Hans",
      },
      {
        id: 3,
        name: "Mark Forster",
      },
    ],
    datenschutzbeauftragte: [
      {
        id: 1,
        name: "Nils Möllers",
      },
      {
        id: 2,
        name: "Sebastian Feldmann",
      },
      {
        id: 3,
        name: "Serkan Taskin",
      },
      {
        id: 4,
        name: "Dennis Dase",
      },
      {
        id: 5,
        name: "Ferris Imken",
      },
    ],
    zwecke: [
      {
        id: 1,
        name: "Beschäftigungsverhältnis",
      },
      {
        id: 2,
        name: "Rechtsgeschäft",
      },
      {
        id: 3,
        name: "Geschäftsmäßige Datenerhebung",
      },
      {
        id: 4,
        name: "Sonstige eigene Geschäftszwecke",
      },
    ],
    rechtsgrundlagen: [
      {
        id: 1,
        name: "Art. 6 Abs. 1 lit. a) DSGVO",
      },
      {
        id: 2,
        name: "Art. 6 Abs. 1 lit. b) DSGVO",
      },
      {
        id: 3,
        name: "Art. 6 Abs. 1 lit. c) DSGVO",
      },
      {
        id: 4,
        name: "Art. 6 Abs. 1 lit. d) DSGVO",
      },
      {
        id: 5,
        name: "Art. 6 Abs. 1 lit. e) DSGVO",
      },
      {
        id: 6,
        name: "Art. 6 Abs. 1 lit. f) DSGVO",
      },
      {
        id: 7,
        name: "Art. 9 Abs. 2 lit. a) DSGVO",
      },
      {
        id: 8,
        name: "Art. 88 DSGVO, § 26 BDSG",
      },
    ],
    kategorien_personen: [
      {
        id: 1,
        name: "Identifikations-/Adressdaten",
        frist: {
          id: null,
          name: null,
        },
      },
      {
        id: 2,
        name: "Personenstammdaten",
        frist: {
          id: null,
          name: null,
        },
      },
      {
        id: 3,
        name: "Personaldaten",
        frist: {
          id: null,
          name: null,
        },
      },
      {
        id: 4,
        name: "Kommunikationsdaten",
        frist: {
          id: null,
          name: null,
        },
      },
      {
        id: 5,
        name: "Protokoll-/Erfassungsdaten",
        frist: {
          id: null,
          name: null,
        },
      },
    ],
    besondere_kategorien_personen: [
      {
        id: 1,
        name: "Daten über rassische und ethnische Herkunft",
      },
      {
        id: 2,
        name: "Daten zu religiösen oder weltanschaulichen Überzeugungen",
      },
      {
        id: 3,
        name: "Daten zur Gesundheit",
      },
      {
        id: 4,
        name: "Biometrische Daten",
      },
      {
        id: 5,
        name: "Daten zum Sexualleben oder der sexuellen Orientierung einer natürlichen Person",
      },
    ],
    kategorien_betroffene: [
      {
        id: 1,
        name: "Mitarbeiter",
      },
      {
        id: 2,
        name: "Kunden",
      },
      {
        id: 3,
        name: "Lieferanten",
      },
      {
        id: 4,
        name: "Webseiten-Besucher",
      },
      {
        id: 5,
        name: "Minderjährige",
      },
    ],
    datenuebertragung: [
      {
        id: 1,
        name: "Es erfolgt eine Datenübermittlung in ein Drittland",
      },
      {
        id: 2,
        name: "Es erfolgt keine Datenübermittlung in ein Drittland",
      },
    ],
    kategorien_empfaenger: [
      {
        id: 1,
        name: "Interne Empfänger",
      },
      {
        id: 2,
        name: "Externe Empfänger",
      },
    ],
    toms: [
      {
        id: 1,
        name: "Es bestehen Datensicherungs- und Backupkonzepte",
      },
      {
        id: 2,
        name: "Regelmäßige Durchführung der Datensicherungs- und Backupkonzepte",
      },
      {
        id: 3,
        name: "Zutrittsbegrenzung in Serverräumlichkeiten auf notwendiges Personal",
      },
      {
        id: 4,
        name: "Brandmeldeanlagen in Serverräumlichkeiten oder im Rechenzentrum",
      },
      {
        id: 5,
        name: "Rauchmelder in Serverräumlichkeiten oder im Rechenzentrum",
      },
      {
        id: 6,
        name: "Wasserlose Brandbekämpfungssysteme in Serverräumlichkeiten oder im Rechenzentrum",
      },
      {
        id: 7,
        name: "Klimatisierte Serverräumlichkeiten",
      },
      {
        id: 8,
        name: "Blitz-/ Überspannungsschutz",
      },
      {
        id: 9,
        name: "Serverräumlichkeiten in separaten Brandabschnitt",
      },
      {
        id: 10,
        name: "Unterbringung von Backupsystemen in separaten Räumlichkeiten und Brandabschnitt",
      },
      {
        id: 11,
        name: "Katastrophen- oder Notfallplan (z.B. Wasser, Feuer, Explosion, Androhung von Anschlägen, Absturz, Erdbeben)",
      },
      {
        id: 12,
        name: "USV-Anlage (Unterbrechungsfreie Stromversorgung)",
      },
    ],
    loeschfrist: [
      {
        id: 0,
        name: "2 Jahre",
      },
      {
        id: 1,
        name: "3 Jahre",
      },
      {
        id: 2,
        name: "6 Jahre",
      },
      {
        id: 3,
        name: "10 Jahre",
      },
    ],
  }),
  methods: {
    create_zweck() {
      const zweck = {
        id: uuid(),
        name: this.search_zweck,
      };

      this.zwecke.push(zweck);
      this.vvt.zweck.push(zweck);
      this.search_zweck = "";
    },
    create_rechtsgrundlage() {
      const rechtsgrundlage = {
        id: uuid(),
        name: this.search_rechtsgrundlage,
      };

      this.rechtsgrundlagen.push(rechtsgrundlage);
      this.vvt.rechtsgrundlage.push(rechtsgrundlage);
      this.search_rechtsgrundlage = "";
    },
    create_kategorie_personen() {
      const kategorie = {
        id: uuid(),
        name: this.search_kategorie_personen,
      };

      this.kategorien_personen.push(kategorie);
      this.vvt.kategorie_personen.push(kategorie);
      this.search_kategorie_personen = "";
    },
    create_besondere_kategorie_personen() {
      const kategorie = {
        id: uuid(),
        name: this.search_besondere_kategorie_personen,
      };

      this.besondere_kategorien_personen.push(kategorie);
      this.vvt.besondere_kategorie_personen.push(kategorie);
      this.search_besondere_kategorie_personen = "";
    },
    create_kategorie_betroffene() {
      const kategorie = {
        id: uuid(),
        name: this.search_kategorie_betroffene,
      };

      this.kategorien_betroffene.push(kategorie);
      this.vvt.kategorie_betroffene.push(kategorie);
      this.search_kategorie_betroffene = "";
    },
    create_kategorie_empfaenger() {
      const kategorie = {
        id: uuid(),
        name: this.search_kategorie_empfaenger,
      };

      this.kategorien_empfaenger.push(kategorie);
      this.vvt.kategorie_empfaenger.push(kategorie);
      this.search_kategorie_empfaenger = "";
    },
    create_tom() {
      const tom = {
        id: uuid(),
        name: this.search_tom,
      };

      this.toms.push(tom);
      this.vvt.tom.push(tom);
      this.search_tom = "";
    },
    create_loeschfrist() {
      const frist = {
        id: uuid(),
        name: this.search_loeschfrist,
        frist: {
          id: null,
          name: null,
        },
      };

      this.loeschfrist.push(frist);

      this.get_kategorie().frist = frist;
      this.search_loeschfrist = "";
    },
    edit_frist(id) {
      console.log(id);
      this.frist_dialog.id = id;
      this.frist_dialog.open = true;
    },
    get_kategorie() {
      return this.vvt.kategorie_personen[
        this.vvt.kategorie_personen.findIndex(
          (x) => x.id == this.frist_dialog.id
        )
      ];
    },
    /**
    open_tom() {
      console.log("Open tom");
      this.tom_dialog = true;
    },
    delete_tom(tom) {
      this.vvt.tom.splice(
        this.vvt.tom.findIndex((t) => t.id === tom.id),
        1
      );
    },
    add_tom(tom) {
      this.vvt.tom.push(tom);
    },
*/
  },
};
</script>

<style>
.hide {
  display: none;
}

.background {
  background-color: #eee;
}
</style>
